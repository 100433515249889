<!--积分明细-->
<template>
    <div class="good">
        <!-- 查詢 -->
        <el-row :gutter="10" style="margin-bottom: 20px;">
            <el-col :span="6">
                <el-select v-model="tableParams.businessTypeSet" multiple placeholder="请选择流水明细"   @change="reload" class="mr15">
                    <el-option v-for="item in ObjToOpt(pointRecordType)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="8">

            </el-col>

        </el-row>

        <!-- 表格 -->
        <el-table :data="tableData" border stripe :header-cell-style="{
            background: '#FAFAFA',
            color: '#000000',
        }">
            <el-table-column label="流水明细" align="center" prop="businessTypeName">
            </el-table-column>
            
            <el-table-column label="获得时间" align="center" >
                <template slot-scope="scope">
                    <span>{{ scope.row.detailType==1?scope.row.addDt:'-' }}</span>
                </template>
            </el-table-column>
           
            <el-table-column label="兑换时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.detailType==2?scope.row.addDt:'-'  }}</span>
                </template>
            </el-table-column>
            <el-table-column label="退款时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.detailType==3?scope.row.addDt:'-'  }}</span>
                </template>
            </el-table-column>
            <el-table-column label="过期时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.detailType==4?scope.row.addDt:'-'  }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收回时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.detailType==5?scope.row.addDt:'-'  }}</span>
                </template>
            </el-table-column>
            <el-table-column label="交易积分" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.amount }}</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <section class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </section>
    </div>
</template>
<script>
import { ObjToOpt } from "@/utils/utils";
import { pointRecordType} from "@/db/objs";
import { getUserBalanceInfo } from "@/api/user/user.js";

export default {
    data() {
        return {
            ObjToOpt,
            pointRecordType,
            
            tableParams: {
                kw: "",
                page: 1,
                size: 10,
                businessType: 2,
                detailType:'',
                memberId: "",
                accountTypeSet:[3],
                businessTypeSet:[]
            },
            tableData: [],
            total: 0,
            currentPage: 1,
        };
    },
    created() {
    
    this.tableParams.memberId = this.$route.query.userId;
  },
    mounted() {
        this.getUserBalanceInfo();

    },
    methods: {
        // 用户账户明细
    getUserBalanceInfo() {
   
      getUserBalanceInfo(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
        kwChange(e) {
            this.tableParams.kw = e;
            this.reload();
        },
        // 【监听】表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getUserBalanceInfo();
        },

        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getUserBalanceInfo();
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.size = size;
            this.reload();
        },

        update(e) {
            this.tableParams.merchantId = e.merchantId;
            this.tableParams.shopId = e.shopId;
            this.reload()
        },
    },
};
</script>
<style lang="scss" scoped>
.good {
    padding: 0.15rem;
    box-sizing: border-box;
}

.table-search {
    display: flex;
    margin-bottom: 0.15rem;
}

.search-L {
    display: flex;
}

.table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
}

.form-footer {
    display: flex;
    justify-content: flex-end;
}
</style>